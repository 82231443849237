import { Container, Graphics, Text } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setIsTimeoutErrorMessage, setReplayBet, setReplayFreeSpinBets, setStressful } from '../../gql';
import i18n from '../../i18next';
import { nextTick } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/viewContainer';
import { SlotMachineState, eventManager } from '../config';

import { REPLAY_TEXT_MARGIN, replayTextStyle } from './config';

class GameReplay extends ViewContainer {
  private text: Text;
  private triangle: Graphics;
  private back: Graphics;
  private container: Container;
  private animation: Animation | undefined;

  constructor() {
    super();
    this.init();
    this.text = this.initText();
    this.triangle = this.initTriangle();
    this.back = this.initBack();
    this.container = this.initContainer();
    this.addChild(this.back, this.container);
  }

  private init() {
    this.visible = false;
    this.position.set(25, 100);

    if (setReplayBet()) {
      nextTick(() => {
        this.startReplay();
      });
    }

    eventManager.addListener(EventTypes.SET_REPLAY_TEXT_VISIBILITY, this.setVisible.bind(this));
  }

  private initText(): Text {
    const text = new Text(i18n.t('replay').toLocaleUpperCase(), replayTextStyle);
    text.anchor.set(0, 0.5);
    text.position.set(25, 0);
    return text;
  }

  private initTriangle(): Graphics {
    const triangle = new Graphics();
    triangle
      .beginFill(0xff0000)
      .drawPolygon([
        16,
        0,
        Math.floor(Math.cos(Math.PI * 0.6666) * 16),
        Math.floor(Math.sin(Math.PI * 0.6666) * 16),
        Math.floor(Math.cos(Math.PI * 1.3333) * 16) + 1,
        Math.floor(Math.sin(Math.PI * 1.3333) * 16) + 1,
      ]);
    return triangle;
  }

  private initBack(): Graphics {
    const back = new Graphics();
    back.beginFill(0x000000, 0.3).drawRect(-20, -25, this.triangle.width + this.text.width + REPLAY_TEXT_MARGIN, 50);
    return back;
  }

  private initContainer(): Container {
    const container = new Container();
    container.addChild(this.triangle, this.text);
    this.animation = Tween.createDelayAnimation(500);
    this.animation.addOnComplete(() => {
      container.visible = !container.visible;
    });
    this.animation.isLoop = true;
    this.animation.start();
    return container;
  }

  private startReplay() {
    this.setVisible(true);
    eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    eventManager.emit(EventTypes.TOGGLE_SPIN);
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state !== SlotMachineState.IDLE) return;

    const replayFreeSpins = setReplayFreeSpinBets();
    if (replayFreeSpins.length || replayFreeSpins.length > 0) return;

    setIsTimeoutErrorMessage(true);
    setStressful({
      show: true,
      type: 'none',
      message: i18n.t('replayBetMessage'),
    });
    //setReplayBet('');
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
    if (visible) {
      this.animation!.isLoop = true;

      this.animation!.start();
    } else {
      this.animation!.isLoop = false;
    }
  }
}
export default GameReplay;
