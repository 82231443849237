import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setGameMode,
  setIsBuyFeaturePopupOpened,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { calcPercentage, isRegularMode } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class InfoBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private isTransition = false;

  private isPopupFreeRoundsOpened = false;

  constructor() {
    super('info');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isTransition: boolean) => {
      this.isTransition = isTransition;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_DURING_HISTORY_REPLAY_VISIBILITY, (visible: boolean) => {
      this.visible = visible;
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });
  };

  private handleClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.INFO_POPUP);

      setIsOpenInfoPopup(!setIsOpenInfoPopup());
      setIsOpenHistoryPopup(false);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsBuyFeaturePopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(setGameMode()) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        this.isPopupFreeRoundsOpened ||
        setIsBuyFeaturePopupOpened() ||
        this.isTransition,
    );
  };

  public override handlePosition(): void {
    this.buttonSprite.anchor.set(0, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.58);
      this.buttonSprite.anchor.set(0, 0);
      x = gap;
      y = gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default InfoBtn;
