import * as PIXI from 'pixi.js';

import { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/query';
import Animation from './slotMachine/animations/animation';
import { WinStages } from './slotMachine/config';
import { IWinLine, Icon, LineSet } from './slotMachine/d';
import { TesterAPI } from './slotMachine/devTool/testerApi';
import { WinData } from './utils/cascade';

declare global {
  interface Window {
    __PIXI_APP__: PIXI.Application;
    __: TesterAPI;
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_NAME: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export type CascadeFallPossibleSymbol = SlotId | '';

export type ScatterPresentationStatus = '' | 'sc3' | 'sc4' | 'sc3→4';

export interface Cascade {
  winPositions: number[][];
  amounts: number[];
  cascadeFall: CascadeFallPossibleSymbol[][];
  multiplierMatrix?: number[][];
  scatterPresentationStatus: ScatterPresentationStatus;
  slotIds: SlotId[];
}

export interface IPositionMultiplier {
  [spinMatrixPosition: number]: number;
}

export interface CascadePayLine {
  pattern: string;
  payoffType: string;
  rewards: {
    type: string;
    multiplier: number;
    count?: number;
    id?: string;
  }[];
  type: string;
  winPositions: number[];
}

export interface CascadeData {
  cascadeHistoryRound: string[][];
  multiplierHistory: IPositionMultiplier[][];
  paylines: CascadePayLine[][];
  spinMatrixesChanges: SlotId[][];
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    status: string;
    userBonusId: string;
    reelSetId: string;
    data: {
      bonuses: UserBonus[];
      features: {
        gameRoundStore: {
          cascadeData: CascadeData;
        };
        // TODO mock version
        initMultiplierMatrix?: number[][];
      };
    };
    createdAt: string;
    updatedAt: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      //undefined
      spinResult: Icon[];
    };
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  id: string;
  bonusId: string;
  coinValue: number;
  coinAmount: number;
  data: UserBonusData;
  betId: string;
  status: BonusStatus;
  rounds: number;
  roundsPlayed: number;
  totalWinAmount: number;
  bonus: Bonus;
  currentRound: number;
  isActive: boolean;
  reelSetId: string;
  // frb
  isFreeBet?: boolean;
};

export type Bonus = {
  coinAmount: number;
  data: unknown;
  description?: string;
  id: string;
  purchasable: boolean;
  reelSetId: string;
  rounds: number;
  title?: string;
  type: BonusType;
};

export type FreeRoundBonus = {
  bonusId: string;
  id: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  totalWinAmount: number;
  roundsPlayed: number;
  isActive: boolean;
  currentRound: number;
};

export type UserBonusData = {
  count: number;
  maxRounds: number;
  storeCoinValue: boolean;
  debitMultiplier: number;
  storeCoinAmount: boolean;
  creditMultiplier: number;
  frbReferenceId?: string;
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export enum EventTypes {
  ADD_WIN_AMOUNT = 'addWinAmount',
  AFTER_WIN = 'afterWin',
  APP_VISIBILITY_CHANGE = 'appVisibilityChange',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_CLOSE_BUY_FEATURE = 'forceCloseBuyFeature',
  FORCE_STOP_REELS = 'forceStopReels',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  HANDLE_SKIP_WIN_FADE_ANIMATION = 'handleSkipWinFadeAnimation',
  HANDLE_START_WIN_FADE_ANIMATION = 'handleStartWinFadeAnimation',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  HIDE_COINS = 'hideCoins',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_LINES = 'hideWinLines',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  NEXT_CASCADE = 'nextCascade',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  PHOENIX_START = 'phoenixStart',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  POST_RENDER = 'postrender',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  REMOVE_ANIMATOR = 'removeAnimator',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  ROLLBACK_REELS = 'rollbackReels',
  SCENE_CHANGE_DOWN = 'sceneChangeDown',
  SCENE_CHANGE_UP = 'sceneChangeUp',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_DURING_HISTORY_REPLAY_VISIBILITY = 'setBottomContainerVisibility',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_BIG_WINS_VISIBILITY = 'setBigWinsVisibility',
  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SUSPENDED = 'setIsSuspended',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  SET_REPLAY_TEXT_VISIBILITY = 'setReplayTextVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SHOW_COINS = 'showCoins',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_WIN_LINES = 'showWinLines',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SOUND_INITIALIZED = 'soundInitialized',
  SPACE_KEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  SPACE_KEY_SPIN = 'spaceKeySpin',
  START_ANTICIPATION_SLOT = 'startAnticipationSlot',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_BIG_WIN_PRESENTATION = 'startBinWinPresentation',
  START_CASCADE_FEATURE = 'startCascadeFeature',
  START_CASCADE_ANIMATION = 'startCascadeAnimation',
  START_FADE_AREA_ANIMATION = 'startFadeAreaAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_WIN_COUNT_UP_MESSAGE = 'startWinCountUpMessage',
  START_SPIDER_WIN_ANIMATION = 'startSpiderWinAnimation',
  THROW_ERROR = 'throwError',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_SPIN = 'toggleSpin',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  UPDATE_BET = 'updateBet',
  UPDATE_FREE_SPINS_COUNT = 'updateFreeSpinsCount',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_WIN_VALUE = 'updateWinValue',
  // frb
  SET_IS_IN_TRANSITION = 'setIsInTransition',
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
}

export interface Events {
  [EventTypes.ADD_WIN_AMOUNT]: (winAmount: number) => void;
  [EventTypes.AFTER_WIN]: () => void;
  [EventTypes.APP_VISIBILITY_CHANGE]: (isActive: boolean) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_WIN_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_WIN_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.NEXT_CASCADE]: (cascadeStep: number) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACE_KEY_SPIN]: () => void;
  [EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.SET_REPLAY_TEXT_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_CASCADE_FEATURE]: () => void;
  [EventTypes.START_FADE_AREA_ANIMATION]: (
    duration: number,
    callbackOutComplete?: () => void,
    callbackInComplete?: () => void,
  ) => void;
  [EventTypes.START_WIN_ANIMATION]: (winData: WinData, cascadeStep: number) => void;
  [EventTypes.START_WIN_COUNT_UP_MESSAGE]: (start: number, end: number) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_SPIDER_WIN_ANIMATION]: () => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.START_BIG_WIN_PRESENTATION]: (winAmount: number) => void;
  [EventTypes.END_BIG_WIN_PRESENTATION]: () => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: SlotId[]) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_DURING_HISTORY_REPLAY_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_BIG_WINS_VISIBILITY]: (stage: WinStages, visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: string, curr: string, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.SCENE_CHANGE_DOWN]: (changeModeCallBack?: () => void) => void;
  [EventTypes.SCENE_CHANGE_UP]: (changeModeCallBack?: () => void) => void;
  [EventTypes.UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.FORCE_CLOSE_BUY_FEATURE]: () => void;
  [EventTypes.SET_IS_SUSPENDED]: (isSuspended: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenMessageBanner: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    symbolMatrix: SlotId[][],
    scatterStopCount: number[],
    multiplierMatrix?: number[][],
  ) => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (boolean) => void;
  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (boolean?) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    inDuration: number;
    outDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS = 'fs',
}

export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  BUY_FEATURE,
}
export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPINS]: 'c96064e6-4bef-432b-b059-a1283e079bec',
  [GameMode.BUY_FEATURE]: 'b3f15fd6-87d9-4ae9-b307-028b6c69ad30',
};

export const freeRoundBonusId = '2d9bda4f-fbca-492d-924a-314ae3c7b6d1';

export const reelSets: Record<GameMode, string> = {
  [GameMode.BASE_GAME]: 'eefd020b-caf4-4c76-979f-c2762c81a1e8',
  [GameMode.FREE_SPINS]: 'deedfecd-c38f-41fb-952e-240b8f2c6d19',
  [GameMode.BUY_FEATURE]: '82212a78-17f3-47b5-aade-79a7f26d7024',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.BASE_GAME]: 'afcb1e65-2c69-459a-ba6d-5a5c52e0e3bf',
  [GameMode.FREE_SPINS]: 'afcb1e65-2c69-459a-ba6d-5a5c52e0e3bf',
  [GameMode.BUY_FEATURE]: 'afcb1e65-2c69-459a-ba6d-5a5c52e0e3bf',
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};
