import { formatNumber } from '@phoenix7dev/utils-fe';

import SlotMachine from '..';
import { BetBonusReward, BetCoinReward, BetReward, BonusStatus, EventTypes, ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setGameMode,
  setHistoryReplayBet,
  setIsHistoryVisible,
  setIsInTransition,
  setLastRegularHistory,
  setSlotConfig,
  setStressful,
  setWinAmount,
} from '../../gql';
import { IBet, ReelSetType } from '../../gql/d';
import i18n from '../../i18next';
import { getGameModeByReelSetId, getSpinResult, normalizeCoins, showCurrency } from '../../utils';
import {
  BASE_GAME_SLOTS_PER_REEL_AMOUNT,
  FREE_SPINS_SLOTS_PER_REEL_AMOUNT,
  SlotMachineState,
  eventManager,
} from '../config';

export const handleIdle = (state: SlotMachineState) => {
  if (setHistoryReplayBet() === null) return;
  if (state !== SlotMachineState.IDLE) return;

  const callbackOutComplete = () => {
    const { gameMode, betAmount, coinAmount, balance, winAmount, slotIds: spinResult } = setLastRegularHistory();
    setBetAmount(betAmount);
    setCoinAmount(coinAmount);
    setWinAmount(winAmount);
    eventManager.emit(EventTypes.UPDATE_BET);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
    eventManager.emit(
      EventTypes.UPDATE_WIN_VALUE,
      formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(winAmount),
        showCurrency: showCurrency(setCurrency()),
      }),
    );

    setGameMode(gameMode);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });

    eventManager.emit(EventTypes.SET_DURING_HISTORY_REPLAY_VISIBILITY, true);
    setIsHistoryVisible(true);

    setIsInTransition(false);
    setHistoryReplayBet(null);
  };

  const clickCallback = () => {
    eventManager.emit(EventTypes.START_FADE_AREA_ANIMATION, 3000, callbackOutComplete);
  };

  eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, false);

  setStressful({
    show: true,
    type: 'none',
    message: i18n.t('replay'),
    callback: clickCallback,
  });
};

export const makeHistoryReplaySettledBet = (historyNode: IBet): ISettledBet => {
  const reelSet = setSlotConfig().reels.find((reelSet) => reelSet.id === historyNode.reelSetId)!;
  const slotPerReelAmount =
    reelSet?.type === ReelSetType.DEFAULT ? BASE_GAME_SLOTS_PER_REEL_AMOUNT : FREE_SPINS_SLOTS_PER_REEL_AMOUNT;
  const spinResult = getSpinResult({
    reelPositions: historyNode.result.reelPositions,
    reelSet,
    icons: setSlotConfig().icons,
    slotPerReelAmount: slotPerReelAmount,
  });

  const rewards: BetReward[] = [];
  const coinReward: BetCoinReward = {
    amount: historyNode.result.winCoinAmount,
    __typename: 'BetCoinReward',
  };
  rewards.push(coinReward);

  const bonus = historyNode.data.features.gameRoundStore.cascadeData.paylines.flatMap((paylines) =>
    paylines.flatMap((payline) => payline.rewards.filter((reward) => reward.type === 'BONUS')),
  )?.[0];

  if (bonus) {
    const bonusReward: BetBonusReward = {
      __typename: 'BetBonusReward',
      userBonusId: '',
      userBonus: {
        id: '',
        bonusId: bonus.id || '',
        coinValue: 0,
        coinAmount: 0,
        data: {
          count: 0,
          maxRounds: 0,
          storeCoinValue: false,
          debitMultiplier: 0,
          storeCoinAmount: false,
          creditMultiplier: 0,
        },
        betId: historyNode.id,
        status: BonusStatus.ACTIVE,
        rounds: 8,
        roundsPlayed: 0,
        totalWinAmount: 0,
        bonus: {
          coinAmount: 0,
          data: undefined,
          id: '',
          purchasable: false,
          reelSetId: '', //
          rounds: bonus.count || 0,
          type: 'FREE_SPIN',
        },
        currentRound: 0,
        isActive: false,
        reelSetId: '',
      },
    };
    rewards.push(bonusReward);
  }

  const settledBet: ISettledBet = {
    bet: {
      ...historyNode,
      slotId: setSlotConfig().id,
      status: '',
      userBonusId: '',
      reelSet,
      lineSet: setSlotConfig().lineSet,
      userBonus: {
        id: '',
        bonusId: '',
        lineSet: setSlotConfig().lineSet,
      },
      result: {
        ...historyNode.result,
        spinResult,
      },
    },
    paylines: [],
    balance: {
      placed: {
        amount: 0,
        currency: setCurrency(),
      },
      settled: {
        amount: 0,
        currency: setCurrency(),
      },
    },
    rewards,
  };

  return settledBet;
};

export const onClickHistoryReplay = (historyNode: IBet) => {
  setHistoryReplayBet(makeHistoryReplaySettledBet(historyNode));

  const reelSetId = historyNode.reelSetId;
  const gameMode = getGameModeByReelSetId(reelSetId);

  if (gameMode !== setGameMode()) {
    setGameMode(gameMode);
  }

  const callbackOutComplete = () => {
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });

    setBetAmount(historyNode.coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
    setCoinAmount(historyNode.coinAmount);
    SlotMachine.getInstance().spin(false);
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
      handleIdle(state);
    });
    eventManager.emit(EventTypes.SET_DURING_HISTORY_REPLAY_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, true);
  };

  const callbackInComplete = () => {
    SlotMachine.getInstance().setPlaceBetResult(setHistoryReplayBet()!);
  };

  setIsInTransition(true);
  setIsHistoryVisible(false);
  eventManager.emit(EventTypes.START_FADE_AREA_ANIMATION, 3000, callbackOutComplete, callbackInComplete);
};
