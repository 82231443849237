/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, GraphQLErrorsType, ISettledBet, IUserBalance, lineSets } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  replayBetGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetResult,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeRoundBonus,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsOpenedMessageBanner,
  setIsPopupFreeRoundsOpened,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setReplayBet,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { canPressSpin, isFreeSpinMode, saveReelPosition, showCurrency } from '../../utils';

import { IPlaceBetInput } from './d';

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const stressful = useReactiveVar(setStressful);

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<unknown>): void => {
    eventManager.emit(EventTypes.PLACE_BET_COMPLETED);

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.placed,
        },
      },
    });

    setUserLastBetResult({ ...setUserLastBetResult(), coinValue: placeBet.bet.coinValue });
    SlotMachine.getInstance().setPlaceBetResult(placeBet);

    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
      setGameHistory(lastThreeSpins);

      const betResult = setBetResult()!;
      setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });

      if (placeBet.bet.coinAmount * placeBet.bet.lineSet.coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
        BgmControl.fadeInMelody(3000);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);

      if (error.graphQLErrors.some((err) => err.extensions?.['type'] === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      const placeBetClone = (() => {
        if (typeof window.structuredClone !== 'function') {
          return JSON.parse(JSON.stringify(placeBet)) as ISettledBet;
        }
        return window.structuredClone(placeBet) as ISettledBet;
      })();

      betCompleteCallback(placeBetClone, client);
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      const spinState = SlotMachine.getInstance().state;
      if (spinState !== SlotMachineState.IDLE || (!isFreeSpinMode(setGameMode()) && !setBrokenGame())) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinMode(setGameMode()) || setBrokenGame()) return;
        resetPopupsStateToClosed();
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const input: IPlaceBetInput = {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.BASE_GAME]!,
          };
          const freeRoundBonus = setFreeRoundBonus() && setFreeRoundBonus().isActive;
          if (freeRoundBonus) {
            // TODO(FRB) Turn it OFF if you spin during a test
            input.userBonusId = setFreeRoundBonus().id;
          }
          fnGet({
            variables: {
              input,
            },
          });
        }

        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SONG_SFX_UI_Close });
        AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );

  useEffect(() => {
    const freeSpin = () => {
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.FREE_SPINS]!,
            userBonusId: setCurrentBonusId(),
          },
        },
      });

      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
    };
    const buyFeatureSpin = () => {
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
      );
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.BUY_FEATURE]!,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
    };

    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      const popupFreeRoundsOpened = setIsPopupFreeRoundsOpened();

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease ||
        popupFreeRoundsOpened
      ) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if ((setGameMode() === GameMode.BASE_GAME || setGameMode() === GameMode.BUY_FEATURE) && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (stressful.show) {
          return;
        }

        eventManager.emit(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER);

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isAutoSpins: false,
            isTransition: setIsInTransition(),
            isBrokenBuyFeature: setBrokenBuyFeature(),
          }) ||
          setIsPopupFreeRoundsOpened()
        ) {
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }

        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);

    if (play) {
      BgmControl.fadeInMelody(500);
    }

    if (!stop) {
      BgmControl.fadeOutMelody(3000);
    }
  }, [winThreeTimes]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
