import { SlotId } from '../config';
import {
  Cascade,
  CascadeData,
  CascadeFallPossibleSymbol,
  CascadePayLine,
  IPositionMultiplier,
  ScatterPresentationStatus,
} from '../global.d';
import { setBetAmount, setCoinAmount, setGameMode, setWinDates } from '../gql';
import { FREE_SPINS_SLOTS_PER_REEL_AMOUNT, REELS_AMOUNT } from '../slotMachine/config';
import { RewardType } from '../slotMachine/d';

import { isFreeSpinMode } from './helper';
import { getSymbolMatrixFromSymbols } from './math';

export const getCascadedSymbolMatrix = (
  initSymbols: SlotId[],
  cascades: Cascade[],
  cascadeStep: number,
): SlotId[][] => {
  let flattenSymbols: CascadeFallPossibleSymbol[] = initSymbols;
  let result: SlotId[][] = [];

  for (let i = 0; i < cascadeStep; i++) {
    const cascade = cascades[i]!;
    const winPositions = [...new Set(cascade.winPositions.flatMap((positions) => positions))];
    const scatterPositions = setWinDates()[i]!.scatterPositions;
    flattenSymbols = flattenSymbols.map((symbol, j) => {
      // leave scatter symbol
      if (i === 0 && scatterPositions.includes(j)) {
        return symbol;
      }

      if (winPositions.includes(j)) {
        return '';
      }
      return symbol;
    });

    const symbolMatrix: SlotId[][] = [];
    getSymbolMatrixFromSymbols<SlotId | ''>(flattenSymbols).forEach((symbols, reelId) => {
      const remainingSymbols: SlotId[] = [];
      symbols.forEach((symbol) => {
        if (symbol !== '') {
          remainingSymbols.push(symbol);
        }
      });

      const fallSymbols = cascade.cascadeFall[reelId]!.filter((v): v is Exclude<typeof v, ''> => v !== '');
      symbolMatrix.push([...fallSymbols, ...remainingSymbols]);
    });

    flattenSymbols = symbolMatrix[0]!.map((_, i) => symbolMatrix.map((row) => row[i]!)).flatMap((v) => v);
    result = symbolMatrix;
  }

  return result;
};

export const getMultiplierMatrix = (multiplierHistory: IPositionMultiplier[]): number[][] => {
  if (!isFreeSpinMode(setGameMode())) {
    return [];
  }

  const matrix: number[][] = [];
  for (let index = 0; index < REELS_AMOUNT; index++) {
    matrix.push(Array<number>(FREE_SPINS_SLOTS_PER_REEL_AMOUNT).fill(0));
  }

  if (multiplierHistory?.length > 0) {
    multiplierHistory.forEach((mp) => {
      for (const [position, multiplier] of Object.entries(mp)) {
        const pos = Number(position);
        const reelId = pos % REELS_AMOUNT;
        const slotPerIndex = Math.floor(pos / REELS_AMOUNT);
        matrix[reelId]![slotPerIndex]! = multiplier as number;
      }
    });
  }
  return matrix;
};

export const getScatterPositions = (slotIds: SlotId[]): number[] => {
  return slotIds.reduce<number[]>((acc, id, index) => {
    if (id === SlotId.SC) {
      acc.push(index);
    }
    return acc;
  }, []);
};

export const getCascadeFromFirstScatterWinOnly = (cascadeData: CascadeData): Cascade => {
  const spinMatrixesChanges = cascadeData.spinMatrixesChanges!;
  const cascadePayLines = cascadeData.paylines!;

  const amounts = cascadePayLines
    .filter((paylines) => paylines.length > 0)
    .reduce((acc, payLines) => {
      acc += payLines.reduce((acc, payline) => {
        acc += payline.rewards.reduce((acc, reward) => {
          if (reward.type === 'COINS') {
            acc += reward.multiplier * setBetAmount();
          }
          return acc;
        }, 0);
        return acc;
      }, 0);
      return acc;
    }, 0);
  const matrix = getSymbolMatrixFromSymbols(spinMatrixesChanges[0]!);
  const cascadeFall = matrix.reduce<CascadeFallPossibleSymbol[][]>((acc, reelSymbols) => {
    const cascadeSymbols = reelSymbols.reduce<CascadeFallPossibleSymbol[]>((acc) => {
      acc.push('');
      return acc;
    }, []);
    acc.push(cascadeSymbols);
    return acc;
  }, []);
  const scatterPositions = getScatterPositions(spinMatrixesChanges[0]!.flat());

  const cascade: Cascade = {
    winPositions: [scatterPositions],
    amounts: [amounts],
    cascadeFall,
    multiplierMatrix: [],
    scatterPresentationStatus: scatterPositions.length === 3 ? 'sc3' : 'sc4',
    slotIds: spinMatrixesChanges[0]!.flat(),
  };

  return cascade;
};

export const existsMultiplierHistory = (i: number, multiplierHistory: IPositionMultiplier[][]): boolean => {
  if (multiplierHistory.length > 1 && multiplierHistory[i + 1] && multiplierHistory[i + 1]!.length > 0) {
    return true;
  }
  return false;
};

export const prevCascadeWinAmounts = (cascades: Cascade[], cascadeStep: number): number => {
  return cascades
    .map((cascade) => cascade.amounts)
    .reduce((sum, amounts, index) => {
      if (index >= cascadeStep) {
        return sum;
      }
      return sum + amounts.reduce((s, v) => s + v);
    }, 0);
};

// TODO fix later(Remove unnecessary information)
export const getCascades = (cascadeData: CascadeData): Cascade[] => {
  const spinMatrixesChanges = cascadeData.spinMatrixesChanges!;
  const cascadeHistoryRound = cascadeData.cascadeHistoryRound!;
  const multiplierHistory = cascadeData.multiplierHistory!;
  const cascadePayLines = cascadeData.paylines!;

  const firstScatterPositions = getScatterPositions(spinMatrixesChanges[0]!.flat()).length;
  const firstScatterPresentationStatus: ScatterPresentationStatus =
    firstScatterPositions === 3 ? 'sc3' : firstScatterPositions === 4 ? 'sc4' : '';

  if (
    (firstScatterPresentationStatus === 'sc3' || firstScatterPresentationStatus === 'sc4') &&
    cascadeHistoryRound.length === 0
  ) {
    const cascade = getCascadeFromFirstScatterWinOnly(cascadeData);
    return [cascade];
  }

  let savingScatterPresentationStatus: ScatterPresentationStatus = firstScatterPresentationStatus;

  const cascades = cascadeHistoryRound?.reduce<Cascade[]>((acc, cascadeHistory, i) => {
    const matrix = getSymbolMatrixFromSymbols(spinMatrixesChanges[i + 1]!);
    const cascadeFall = matrix.reduce<CascadeFallPossibleSymbol[][]>((acc, reelSymbols, reelId) => {
      const cascadeSymbols = reelSymbols.reduce<CascadeFallPossibleSymbol[]>((acc, symbol, slotIndex) => {
        acc.push(symbol === cascadeHistory[reelId]![slotIndex]! ? '' : symbol);
        return acc;
      }, []);
      acc.push(cascadeSymbols);
      return acc;
    }, []);

    const payLines = cascadePayLines[i]!;
    const winPositions: number[][] = [];
    const amounts: number[] = [];
    payLines.forEach((payLine) => {
      winPositions.push(payLine.winPositions);
      amounts.push(
        payLine.rewards.reduce((acc, reward) => {
          if (reward.type === RewardType.COINS) {
            acc += reward.multiplier * setCoinAmount();
          }
          return acc;
        }, 0),
      );
    });
    const multiplierMatrix = existsMultiplierHistory(i, multiplierHistory)
      ? getMultiplierMatrix(multiplierHistory[i + 1]!.flat())
      : [];

    // scatter presentation
    const scatterPositions = getScatterPositions(spinMatrixesChanges[i + 1]!.flat());
    let scatterPresentationStatus: ScatterPresentationStatus = '';
    if (scatterPositions.length === 3 && savingScatterPresentationStatus === '') {
      savingScatterPresentationStatus = 'sc3';
      scatterPresentationStatus = 'sc3';
    } else if (scatterPositions.length === 4 && savingScatterPresentationStatus !== 'sc4') {
      if (savingScatterPresentationStatus !== 'sc3') {
        // 0~2→4
      } else {
        // 3→4
      }
      savingScatterPresentationStatus = 'sc4';
      scatterPresentationStatus = 'sc4';
    }

    const cascade: Cascade = {
      winPositions,
      amounts,
      cascadeFall,
      multiplierMatrix,
      scatterPresentationStatus,
      slotIds: spinMatrixesChanges[i + 1]!.flat(),
    };
    acc.push(cascade);
    return acc;
  }, []);
  return cascades;
};

export interface WinData {
  winPositions: number[][];
  scatterPositions: number[];
  amounts: number[];
  scatterPresentationStatus: ScatterPresentationStatus;
}

export const getScatterWinAmount = (cascadePayLines: CascadePayLine[][]) => {
  const bonusPayLines = cascadePayLines
    .flatMap((v) => v)
    .filter((payline) => payline.rewards.filter((reward) => reward.type === 'BONUS').length > 0);

  const amount = bonusPayLines.reduce((acc, payline) => {
    acc += payline.rewards.reduce((acc, reward) => {
      if (reward.type === 'COINS') {
        acc += reward.multiplier * setBetAmount();
      }
      return acc;
    }, 0);
    return acc;
  }, 0);
  return amount;
};

export const getPayLinesExcludingScatterWin = (cascadePayLines: CascadePayLine[][]) => {
  return cascadePayLines.reduce<CascadePayLine[][]>((acc, paylines) => {
    const scatterPayLines = paylines.filter((payline) => payline.payoffType === 'ANY');
    if (scatterPayLines.length === 0) {
      acc.push(paylines);
    }
    return acc;
  }, []);
};

// TODO fix later(rename)
export const getWinDates = (cascadeData: CascadeData) => {
  const spinMatrixesChanges = cascadeData.spinMatrixesChanges!;
  const normalPayLines = getPayLinesExcludingScatterWin(cascadeData.paylines);

  let savingScatterPresentationStatus: ScatterPresentationStatus = '';

  const winDates = spinMatrixesChanges.reduce<WinData[]>((acc, slotIds, i) => {
    console.log(acc, slotIds, i);

    const payLines = normalPayLines[i];
    const winPositions: number[][] = [];
    const amounts: number[] = [];
    payLines?.forEach((payLine) => {
      winPositions.push(payLine.winPositions);
      amounts.push(
        payLine.rewards.reduce((acc, reward) => {
          if (reward.type === RewardType.COINS) {
            acc += reward.multiplier * setCoinAmount();
          }
          return acc;
        }, 0),
      );
    });

    const scatterPositions = getScatterPositions(slotIds.flat());
    let scatterPresentationStatus: ScatterPresentationStatus = '';
    if (scatterPositions.length === 3 && savingScatterPresentationStatus === '') {
      savingScatterPresentationStatus = 'sc3';
      scatterPresentationStatus = 'sc3';
      // TODO fix lataer(Get it later from ICON)
      amounts.push(setBetAmount() * 1);
    } else if (scatterPositions.length === 4 && savingScatterPresentationStatus !== 'sc4') {
      if (savingScatterPresentationStatus !== 'sc3') {
        // x→4
        savingScatterPresentationStatus = 'sc4';
        scatterPresentationStatus = 'sc4';
        amounts.push(setBetAmount() * 2);
      } else {
        savingScatterPresentationStatus = 'sc4';
        scatterPresentationStatus = 'sc3→4';
        amounts.push(setBetAmount() * 1);
      }
    }

    if (amounts.length > 0) {
      const data: WinData = {
        winPositions,
        amounts,
        scatterPositions,
        scatterPresentationStatus,
      };
      acc.push(data);
    }
    return acc;
  }, []);

  return winDates;
};

export const getPrevWinAmounts = (winData: WinData[], step: number): number => {
  return winData
    .map((cascade) => cascade.amounts)
    .reduce((sum, amounts, index) => {
      if (index >= step) {
        return sum;
      }
      return sum + amounts.reduce((s, v) => s + v);
    }, 0);
};
